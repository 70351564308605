export interface ConfigDto {
    MIN_SERVER_SPEED: number;
    MAX_SERVER_SPEED: number;
    TIME_SCALE: number;
    FIXED_INTERVAL_H: number; // non-decreasing hours
    DECAY_INTERVAL_H: number; // every hour speed will decrease by DECAY_RATE
    DECAY_RATE: number;
    REWARD_PER_HOUR: number;
    FARMING_SESSION_INTERVAL_H: number;
}

export interface InitDataDto {
    user: UserDto;
    config: ConfigDto;
    token: string;
    serverTime: string;
}

export interface FarmSessionInfoDto {
    lastFarmedCoins: number;
    farmedCoins: number;
    lastSpeed: number;
    currentSpeed: number;
    lastInteractionTime: string;
    nextInteractionTime: string;
    startTime: string;
    endTime: string;
}

export enum UserStatus {
    CREATED_BY_MANAGER = 0,
    INTERACT_WITH_BOT = 1,
    LAUNCH_GAME = 2,
    REGISTERED = 3,
    FARMING_START = 4,
    INTERACT_WITH_FARMING = 5,
    CLAIMED_FARM_SESSION = 6,
}

export interface UserDto {
    balance: number;
    createdAt: string;
    isPremium: boolean;
    status: UserStatus;
    telegramId: string;
    username: string;
    refCode: string;
    totalClaimedSessions: number;
    farmSessionInfo?: FarmSessionInfoDto;
}

export interface ReferralInvitedUserItemDto {
    username?: string;
    name?: string;
    balance: number;
    totalReferrals: number;
    avatarUrl?: string;
}

export interface ReferralInfoDto {
    multiplierLevelOne: number;
    multiplierLevelTwo: number;
    availableRefClaim: number;
    totalEarningFromRef: number;
}

export interface ReferralInfoUsers {
    page: number;
    pageSize: number;
    count: number;
    result: Array<ReferralInvitedUserItemDto>;
}

export enum TaskItemStatus {
    AUTO = 'auto',
    CAN_OPEN = 'can_open',
    OPENED = 'opened',
    CAN_CLAIM = 'can_claim',
    CLAIMED = 'claimed',
}

export type TaskItemDto = {
    id: string;
    description: string;
    reward: number;
    status: TaskItemStatus;

    subscribeTaskInfo: {
        description: string;
        url: string;
    };
};

export type TasksResponseDTO = {
    tasks: TaskItemDto[];
};
