import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FirstScreen } from './FirstScreen';
import { CreateAccountScreen } from './CreateAccountScreen';
import { AccountScreen } from './AccountScreen/AccountScreen';
import { createUser, doSignIn } from '../../modules/api';
import { UserStatus, UserDto } from '../../modules/dto';
import { Config } from '../../modules/store';

enum STATE {
    FIRST = 'first',
    CREATE = 'create',

    ACCOUNT = 'account',
}

export const Main = () => {
    const [state, setState] = useState<STATE | null>(null);
    const [user, setUser] = useState<UserDto | null>(null);
    const [config, setConfig] = useState<Config>(new Config());
    const [isShowIntro, setIsShowIntro] = useState(false);

    const onCreateAccount = useCallback(() => {
        setState(STATE.CREATE);

        createUser().then((user) => {
            setUser((prev) => {
                if (prev) {
                    return {
                        ...prev,
                        ...user,
                    };
                }

                return user;
            });
        });
    }, []);

    const renderScreen = useMemo(() => {
        switch (state) {
            case STATE.FIRST:
                return <FirstScreen onCreateAccount={onCreateAccount} />;
            case STATE.CREATE:
                return (
                    <CreateAccountScreen
                        onNextScreen={() => {
                            setIsShowIntro(true);
                            setState(STATE.ACCOUNT);
                        }}
                    />
                );
            case STATE.ACCOUNT:
                return (
                    <AccountScreen
                        user={user}
                        setUser={setUser}
                        isShowIntro={isShowIntro}
                        setIsShowIntro={setIsShowIntro}
                        config={config}
                    />
                );
        }
    }, [state, user, setUser, onCreateAccount, isShowIntro, config]);

    useEffect(() => {
        doSignIn().then((initData) => {
            if (initData) {
                let newConfig: Config = {
                    ...config,
                    config: initData.config,
                    serverTimeDiffMs:
                        new Date(initData.serverTime).getTime() - new Date().getTime(),
                };
                setConfig(newConfig);
                setUser(initData.user);

                if (
                    initData?.user?.status === UserStatus.REGISTERED ||
                    initData?.user?.status === UserStatus.INTERACT_WITH_FARMING ||
                    initData?.user?.status === UserStatus.FARMING_START ||
                    initData?.user?.status === UserStatus.CLAIMED_FARM_SESSION
                ) {
                    setState(STATE.ACCOUNT);
                } else {
                    setState(STATE.FIRST);
                }
            }
        });
        // eslint-disable-next-line
    }, []);

    if (!user) {
        return null;
    }

    return (
        <div className="flex max-w-[480px] w-full text-white h-full flex-col">{renderScreen}</div>
    );
};
