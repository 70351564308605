import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import eruda from 'eruda';
import { SDKProvider } from '@tma.js/sdk-react';
import ReactGA from 'react-ga4';
import { isDebugMode } from './modules/config';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <SDKProvider acceptCustomStyles>
            <App />
            <ToastContainer />
        </SDKProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (isDebugMode()) {
    eruda.init();
}

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID ?? '');
