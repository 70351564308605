import {
    memo,
    MouseEventHandler,
    TouchEventHandler,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { AudioKind, EggView, TARGET_BULB_COUNT } from '../../Egg/EggView';
import { Button } from 'antd';
import { UserDto } from '../../../../modules/dto';
import { claimFarmSession, makeTap } from '../../../../modules/api';
import {
    clamp,
    getServerTime,
    isFarmingSessionActive,
    isFarmingSessionFinished,
} from '../../../../modules/utils';
import { Config, Store } from '../../../../modules/store';
import { mergeDeepRight } from 'ramda';
import { EggState } from '../../Egg/EggModel';
import { useHapticFeedback } from '@tma.js/sdk-react';
import { Countdown } from './Countdown';
import EventEmitter, { EVENTS } from '../../../../helpers/eventemitter';
import { SvgCircle } from './SvgCircle';
import ProgressBar from '@ramonak/react-progress-bar';
import { SvgGlass } from './SvgGlass';
import clsx from 'clsx';

export enum HomeTouchEnum {
    top = 'TOP',
    left = 'LEFT',
    bottom = 'BOTTOM',
    right = 'RIGHT',
}

type Props = {
    user: UserDto | null;
    setUser?:
        | ((value: ((prevState: UserDto | null) => UserDto | null) | UserDto | null) => void)
        | undefined;
    store?: Store;
    setStore?: ((value: ((prevState: Store) => Store) | Store) => void) | undefined;
    isShowIntro: boolean;
    setIsShowIntro: (value: ((prevState: boolean) => boolean) | boolean) => void;
    config?: Config | undefined;
};

let touches = false;

export const Home: React.FC<Props> = memo(
    ({ user, setUser, isShowIntro, setIsShowIntro, config }) => {
        const [state, setState] = useState<EggState>(EggState.NONE);
        const [sessionFarmedCoins, setSessionFarmedCoins] = useState<string>('');
        const [tapCount, setTapCount] = useState<number>(0);
        const circleContainerRef = useRef<HTMLDivElement>(null);
        const [isLoadingClaim, setIsLoadingClaim] = useState(false);

        const containerRef = useRef<HTMLDivElement>(null);
        const eggView = useRef<EggView | null>(null);
        const haptic = useHapticFeedback();
        const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
        const [isActiveTouch, setIsActiveTouch] = useState(false);
        const [touchOrientation, setTouchOrientation] = useState<HomeTouchEnum>(HomeTouchEnum.top);

        const onClickEgg = () => {
            setIsShowIntro(false);

            if (
                !(
                    isFarmingSessionActive(user, config?.serverTimeDiffMs) ||
                    isFarmingSessionFinished(user, config?.serverTimeDiffMs)
                )
            ) {
                setTimeout(() => {
                    try {
                        haptic.impactOccurred('light');
                    } catch (error) {
                        console.error('Error:', error);
                    }
                }, 0);
            }
        };

        function onDown() {
            eggView.current?.playMusic();

            let fixedState;
            if (state === EggState.NONE) {
                fixedState = !user || !user.farmSessionInfo ? EggState.GROWTH : EggState.HATCHING;
            } else {
                fixedState = state;
            }

            const tapSound =
                fixedState === EggState.HATCHING ? AudioKind.TAP : AudioKind.TAP_FARMING;
            console.log('state', state, tapSound);
            eggView.current?.playSound(tapSound);

            if (!user || !user.farmSessionInfo) {
                switch (state) {
                    case EggState.NONE:
                        setState(EggState.GROWTH);
                        break;
                    case EggState.GROWTH:
                        if (tapCount + 1 >= TARGET_BULB_COUNT) {
                            setTapCount(0);
                            setState(EggState.HATCHING);
                            makeTap(1).then((userUpdates) => {
                                if (user && setUser && userUpdates && userUpdates !== true) {
                                    // eggModel.rotationSpeed = TAP_DENIED_ROTATION_SPEED;
                                    user = mergeDeepRight(user, userUpdates);
                                    setUser(user);
                                    console.log(
                                        `after tap = ${user?.farmSessionInfo?.lastFarmedCoins} farmedCoins=${user?.farmSessionInfo?.farmedCoins}`,
                                    );
                                }
                            });
                            eggView.current?.playSound(AudioKind.START_FARMING);
                        } else {
                            setTapCount(tapCount + 1);
                        }
                        break;
                    case EggState.HATCHING:
                        // just claimed
                        break;
                    case EggState.DISAPPEAR:
                        setState(EggState.GROWTH);
                        break;
                }
            } else {
                switch (state) {
                    case EggState.NONE:
                        setState(EggState.HATCHING);
                        break;
                }
            }
        }

        const onMouseMove: MouseEventHandler<HTMLDivElement> = (e: any) => {
            if (!touches) {
                EventEmitter.emit(EVENTS.MOVE, e);
            }
        };
        const onTouchMove: TouchEventHandler<HTMLDivElement> = (e: any) => {
            EventEmitter.emit(EVENTS.MOVE, {
                pageX: e.touches[0].clientX,
                pageY: e.touches[0].clientY,
            });
        };
        const onMouseDown: MouseEventHandler<HTMLDivElement> = (e: any) => {
            // ignore mouse event of there was one touch event
            if (!touches) {
                console.log('onMouseDown');
                onDown();
                EventEmitter.emit(EVENTS.DOWN, e);
            }
        };
        const onTouchDown: TouchEventHandler<HTMLDivElement> = (e: any) => {
            touches = true;
            console.log('onTouchDown');
            onDown();

            EventEmitter.emit(EVENTS.DOWN, {
                pageX: e.touches[0].clientX,
                pageY: e.touches[0].clientY,
                buttons: 1,
            });
        };
        const onMouseUp: MouseEventHandler<HTMLDivElement> = (e: any) => {
            if (!touches) {
                EventEmitter.emit(EVENTS.UP, e);
            }
        };
        const onTouchEnd: TouchEventHandler<HTMLDivElement> = () => {
            EventEmitter.emit(EVENTS.UP, {});
        };

        const onTouchStartCircle: TouchEventHandler<HTMLDivElement> = (e) => {
            const elemBounding = circleContainerRef?.current?.getBoundingClientRect();

            if (elemBounding) {
                const elementLeftEdge = elemBounding.left;
                const elementTopEdge = elemBounding.top;
                const elementRightEdge = elemBounding.right;
                const elementBottomEdge = elemBounding.bottom;

                const mouseX = e.touches[0].pageX;
                const mouseY = e.touches[0].pageY;

                const topEdgeDist = Math.abs(elementTopEdge - mouseY);
                const bottomEdgeDist = Math.abs(elementBottomEdge - mouseY);
                const leftEdgeDist = Math.abs(elementLeftEdge - mouseX);
                const rightEdgeDist = Math.abs(elementRightEdge - mouseX);

                const min = Math.min(topEdgeDist, bottomEdgeDist, leftEdgeDist, rightEdgeDist);

                if (min === leftEdgeDist) {
                    setTouchOrientation(HomeTouchEnum.left);
                } else if (min === bottomEdgeDist) {
                    setTouchOrientation(HomeTouchEnum.bottom);
                } else if (min === rightEdgeDist) {
                    setTouchOrientation(HomeTouchEnum.right);
                } else if (min === topEdgeDist) {
                    setTouchOrientation(HomeTouchEnum.top);
                }
            }

            setIsActiveTouch(true);
        };

        useEffect(() => {
            intervalRef.current = setInterval(() => {
                if (eggView.current && user && user.farmSessionInfo && config) {
                    const metrics = eggView.current.calcSessionMetrics(user.farmSessionInfo);
                    const farmedCoins = metrics.currentFarmedCoins;
                    const formatedSessionFarmedCoins = farmedCoins.toFixed(2);
                    setSessionFarmedCoins((prev: string) => {
                        if (prev === formatedSessionFarmedCoins) {
                            return prev;
                        } else {
                            return formatedSessionFarmedCoins;
                        }
                    });
                    setTapCount((prev) =>
                        isFarmingSessionFinished(user, config?.serverTimeDiffMs) ? 0 : prev,
                    );
                }
            }, 500);
            return () => {
                intervalRef.current && clearInterval(intervalRef.current);
            };
            // eslint-disable-next-line
        }, [user]);

        useEffect(() => {
            if (containerRef.current && eggView.current === null) {
                eggView.current = new EggView(containerRef.current);
            }

            return () => {
                if (eggView.current) {
                    eggView.current.destroy();
                    eggView.current = null;
                }
            };
        }, [containerRef]);

        useEffect(() => {
            if (eggView.current && config) {
                eggView.current.setConfig(config);
            }
        }, [config]);

        useEffect(() => {
            if (eggView.current && user) {
                eggView.current.setUser(user);
            }
        }, [user]);

        // useEffect(() => {
        //     if (tapProgress >= 100) {
        //         if (audioFarming) {
        //             audioFarming.current.currentTime = 0;
        //             audioFarming.current.play?.();
        //         }
        //     }
        //
        //     // eslint-disable-next-line react-hooks/exhaustive-deps
        // }, [tapProgress]);

        const renderControls = () => {
            if (isFarmingSessionActive(user, config?.serverTimeDiffMs)) {
                const currentDate = getServerTime(config?.serverTimeDiffMs).getTime();
                const delta = new Date(user?.farmSessionInfo?.endTime ?? 0).getTime() - currentDate;
                const farmingProgress = user?.farmSessionInfo?.endTime
                    ? clamp(
                          100 -
                              (delta /
                                  (new Date(user?.farmSessionInfo?.endTime ?? 0).getTime() -
                                      new Date(user?.farmSessionInfo?.startTime ?? 0).getTime())) *
                                  100,
                          0,
                          100,
                      )
                    : 0;
                return (
                    <div className="py-3 flex w-full rounded-[16px] items-center justify-center cursor-not-allowed border-[1px] border-[#ffffff1a] font-mono text-[18px] leading-[25px] h-auto font-semibold relative overflow-hidden !text-white bg-[#ffffff0d]">
                        <ProgressBar
                            className="w-full absolute w-full h-full left-0"
                            width="100%"
                            height="100%"
                            bgColor="#1B1B1B"
                            baseBgColor="transparent"
                            borderRadius="16px"
                            customLabel={' '}
                            completed={farmingProgress}
                            transitionDuration="0"
                        />
                        <span className="z-[2]">
                            Farming&#8201;
                            {sessionFarmedCoins
                                ? parseFloat(parseFloat(sessionFarmedCoins)?.toFixed(2))
                                : 0}
                            &#8201;MTGN
                        </span>
                    </div>
                );
            }

            if (isFarmingSessionFinished(user, config?.serverTimeDiffMs)) {
                return (
                    <Button
                        className="py-3 font-mono font-semibold text-[18px] leading-[25px] h-auto !text-black disabled:!text-white"
                        type="primary"
                        block
                        disabled={isLoadingClaim}
                        onClick={() => {
                            if (!isLoadingClaim) {
                                setIsLoadingClaim(true);
                                setTimeout(() => {
                                    try {
                                        haptic.impactOccurred('medium');
                                    } catch (error) {
                                        console.error('Error:', error);
                                    }
                                }, 0);
                                eggView.current?.playSound(AudioKind.CLAIM);
                                claimFarmSession()
                                    .then((userUpdates) => {
                                        if (userUpdates && setUser && user) {
                                            // eslint-disable-next-line react-hooks/exhaustive-deps
                                            user = mergeDeepRight(user, userUpdates);
                                            setUser(user);
                                            // if (eggView.current) {
                                            //     eggView.current.onClaimed(user);
                                            // }
                                            setState(EggState.GROWTH);
                                        }
                                    })
                                    .finally(() => {
                                        setIsLoadingClaim(false);
                                    });
                            }
                        }}
                    >
                        {isLoadingClaim
                            ? 'Loading...'
                            : `Claim ${parseFloat(parseFloat(sessionFarmedCoins)?.toFixed(2))} MTGN`}
                    </Button>
                );
            }

            return (
                <div className="flex h-[50px] items-center justify-center w-full px-4">
                    <ProgressBar
                        className="w-full"
                        width="100%"
                        height="2px"
                        bgColor="#3EDC03"
                        borderRadius="16px"
                        transitionDuration="0"
                        customLabel={' '}
                        completed={Math.round((tapCount / TARGET_BULB_COUNT) * 100)}
                    />
                </div>
            );
        };

        const renderCanvas = useMemo(() => {
            return (
                <div
                    className="flex w-full justify-center items-center h-full z-[2]"
                    ref={containerRef}
                />
            );
        }, []);

        return (
            <div className="flex h-full flex-col w-full justify-between relative items-center">
                {isShowIntro && (
                    <div className="flex absolute top-0 w-full bottom-0 right-0 left-0 z-[50] cursor-pointer pointer-events-none">
                        <div className="relative h-full w-full flex items-center justify-center ">
                            <div className="absolute top-0 left-0 right-0 bottom-0 blur-[32px] bg-[#00000080] opacity-75" />
                            <span className="text-white text-[18px] z-[2]">Tap</span>
                        </div>
                    </div>
                )}
                <div className="w-auto pt-3 gap-2 items-center justify-center flex z-[2]">
                    <svg
                        className="mb-1"
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_2271_634)">
                            <circle cx="12.5" cy="12" r="12" fill="white" />
                            <path
                                d="M16.0689 10.1486C16.0588 9.25268 17.1605 10.0386 18.4196 9.37318C20.5621 8.23883 19.4629 4.3249 16.6909 5.32303C14.5078 6.10896 15.0916 9.27101 14.0128 9.20814C12.4567 9.11645 12.8781 5.24182 8.9434 4.82266C6.76792 4.59212 5.25498 6.1797 5.48852 7.9297C5.7906 10.2036 8.3494 9.82116 8.34432 10.8507C8.3367 11.9641 4.72444 11.5816 4.14566 14.9061C3.49835 18.6314 10.1619 20.6591 12.0099 17.966C12.5607 17.1644 12.6064 15.9357 11.3753 15.1131C9.98164 14.1831 8.6794 11.9798 10.0629 10.146C10.6493 9.37056 12.1571 9.54871 12.4998 10.573C12.8603 11.6471 12.0226 12.0532 11.7256 12.7108C11.3017 13.5831 11.8322 14.4817 12.741 14.5813C13.5381 14.6677 13.9848 14.225 14.0178 13.6644C14.0737 12.7108 13.4848 12.7265 13.7005 11.9877C14.061 10.7512 16.6909 11.5214 16.0512 13.9368C15.7161 15.1995 14.1905 15.1838 14.1524 16.7976C14.1092 18.6629 15.8049 19.0742 17.229 19.0401C19.7345 18.9798 21.2119 17.2665 20.8464 14.2224C20.3463 10.0779 16.0816 11.3066 16.0689 10.1486Z"
                                fill="#050505"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_2271_634">
                                <rect
                                    width="24"
                                    height="24"
                                    fill="white"
                                    transform="translate(0.5)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                    <span className="font-druk text-white text-2xl font-bold leading-8">
                        {user ? parseFloat(parseFloat(String(user.balance)).toFixed(2)) : 0.0}
                    </span>
                </div>
                <div
                    onClick={onClickEgg}
                    onMouseDown={onMouseDown}
                    onTouchStart={onTouchDown}
                    onMouseMove={onMouseMove}
                    onTouchMove={onTouchMove}
                    onMouseUp={onMouseUp}
                    onTouchEnd={onTouchEnd}
                    onTouchCancel={onTouchEnd}
                    // onMouseLeave={onUpContainer}
                    className="w-full flex overflow-hidden flex-grow absolute bottom-0 top-0 select-none items-center justify-center"
                >
                    <div
                        ref={circleContainerRef}
                        onTouchStart={onTouchStartCircle}
                        onTouchEnd={() => setIsActiveTouch(false)}
                        className="w-full max-w-[328px] max-h-[328px] h-full flex relative items-center justify-center rounded-[100%] overflow-hidden"
                    >
                        {isActiveTouch &&
                            !(
                                isFarmingSessionActive(user, config?.serverTimeDiffMs) ||
                                isFarmingSessionFinished(user, config?.serverTimeDiffMs)
                            ) && (
                                <img
                                    alt="light"
                                    className={clsx('absolute pointer-events-none  z-[4]', {
                                        'top-[-13%]': touchOrientation === HomeTouchEnum.top,
                                        'left-[-33%] rotate-[-90deg]':
                                            touchOrientation === HomeTouchEnum.left,
                                        'bottom-[-13%] rotate-[-180deg]':
                                            touchOrientation === HomeTouchEnum.bottom,
                                        'right-[-33%] rotate-[-270deg]':
                                            touchOrientation === HomeTouchEnum.right,
                                    })}
                                    src="/assets/light.png"
                                />
                            )}

                        <div className="absolute pointer-events-none z-[4]">
                            <SvgGlass />
                        </div>

                        <div className="flex items-center justify-center absolute z-[3]">
                            <SvgCircle isPremium={!!user?.isPremium} />
                        </div>
                        {renderCanvas}
                    </div>
                </div>
                <div className="px-4 w-full flex pb-7 flex-col gap-2 z-[60] items-center">
                    {isFarmingSessionActive(user, config?.serverTimeDiffMs) && (
                        <Countdown
                            endTime={user?.farmSessionInfo?.endTime}
                            serverTimeDiffMs={config?.serverTimeDiffMs}
                        />
                    )}
                    {renderControls()}
                </div>
            </div>
        );
    },
);
